import React from "react";
import styles from "./ProjectCard.module.css";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as GithubIcon } from "assets/icons/socials/github.svg";

function ProjectCard(props) {
  const item = props.props;
  return (
    <div className={`${styles.wrapper} group flex justify-center overflow-hidden relative rounded-3xl shadow-2xl`}>
      <img
        className="transition-all duration-500 ease-in-out group-hover:scale-110 group-hover:blur"
        src={item.img}
        alt={item.title}
      />
      <div className="absolute bg-black w-full h-full flex flex-col justify-center px-4 md:px-12 lg:px-24 transition-all duration-500 top-full group-hover:top-0 opacity-0 group-hover:opacity-100 bg-opacity-0 group-hover:bg-opacity-75">
        <h2 className="text-gray-100 text-center text-2xl sm:text-4xl mb-1 sm:mb-2 font-semibold tracking-tight">
          {item.title}
        </h2>
        <h3 className="text-gray-300 text-center sm:text-xl mb-2 sm:mb-4 lg:mb-8 font-semibold">
          {item.subtitle}
        </h3>
        <p className="text-gray-200 text-center mb-4 lg:mb-8 hidden sm:inline">
          {item.description}
        </p>
        <div className="flex justify-center space-x-6">
          <a
            className={styles.icon}
            href={item.github}
            target="_blank"
            rel="noreferrer"
          >
            <GithubIcon />
          </a>
          <a
            className={styles.icon}
            href={item.website}
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
