import React from "react";
import { socialItems } from "data/socials.js";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className="pt-24 sm:pt-32 lg:pt-48 xl:pt-64 pb-8 sm:pb-16 md:pb-32 xl:pb-48 px-4 sm:px-8 lg:px-24 xl:px-48 w-full bg-primary dark:bg-neutral-900 duration-300 flex justify-center">
      <div className="flex justify-center items-center flex-col w-full max-w-7xl">
        <div className="w-full max-w-7xl mb-12 sm:mb-24">
          <h2 className="pb-2 sm:pb-4 tracking-widest uppercase text-base sm:text-lg text-white dark:text-gray-200">
            Say hello
          </h2>
          <div className="flex flex-col lg:flex-row justify-between flex-wrap">
            {socialItems
              .filter((socialItems) => socialItems.contact === true)
              .map((contactSocialItems) => (
                <div
                  key={contactSocialItems.id}
                  className={`w-1/2 flex ${
                    contactSocialItems.id === "Email" &&
                    "order-last lg:order-none"
                  }`}
                >
                  <a
                    id={contactSocialItems.id}
                    href={contactSocialItems.href}
                    target="_blank"
                    rel="noreferrer"
                    className="text-base sm:text-xl py-1 sm:py-2 lg:py-4 text-white dark:text-gray-300 group"
                  >
                    <div className="relative">
                      <p className="transition-all duration-300 group-hover:opacity-70">
                        {contactSocialItems.id === "Email"
                          ? contactSocialItems.name
                          : `My ${contactSocialItems.name}`}
                      </p>
                      <span className="absolute bg-white dark:bg-gray-300 opacity-0 -translate-x-2 -ml-8 w-8 h-full bottom-0 left-full transition-all duration-300 ease-in-out group-hover:opacity-30 group-hover:translate-x-2" />
                    </div>
                  </a>
                </div>
              ))}
          </div>
        </div>
        <div className="w-full flex flex-col-reverse sm:flex-row justify-between items-center border-t-2 pt-4 sm:pt-8 xl:pt-16 dark:border-gray-200 duration-300">
          <p className="text-base sm:text-xl text-white dark:text-gray-300 mt-4 sm:mt-0 duration-300">
            &#169; Vincent Trinh {year}
          </p>
          <div className="flex space-x-2 sm:space-x-4">
            {socialItems
              .filter((socialItems) => socialItems.network === true)
              .map((networkSocialItems) => (
                <a
                  href={networkSocialItems.href}
                  target="_blank"
                  rel="noreferrer"
                  key={networkSocialItems.id}
                  className="p-2 group"
                >
                  <div className="h-6 w-6 text-white dark:text-gray-300 hover:opacity-70 transition-all duration-300 group-hover:opacity-70">
                    {networkSocialItems.icon}
                  </div>
                </a>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
