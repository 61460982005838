import React from "react";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { textVariant, fadeIn } from "utils/motion";
import styles from "./Hero.module.css";
import { socialItems } from "data/socials.js";

function Hero() {
  return (
    <div
      className="container_wrapper container_wrapper__light h-screen relative"
      name="home"
    >
      <div
        className="flex flex-col items-center"
      >
        <motion.h1
          variants={textVariant(0.5)}
          className="text-6xl sm:text-8xl mb-6 sm:mb-8 tracking-tight text-center"
        >
          Hey, I&apos;m <span className="text-primary">Vincent.</span>
        </motion.h1>
        <motion.h2
          variants={textVariant(0.75)}
          className="text-lg sm:text-2xl max-w-md sm:max-w-2xl mb-4 sm:mb-6 text-center"
        >
          I&apos;m a <span className="text-primary">Frontend Developer</span>{" "}
          that&apos;s passionate about building web applications with great user
          experiences.
        </motion.h2>
        <div
          className="flex justify-center"
        >
          {socialItems.map((link, index) => (
            <motion.a
              variants={fadeIn("up", "spring", (0.75 + index * 0.25), 1.5)}
              href={link.href}
              target="_blank"
              rel="noreferrer"
              key={link.id}
              className="px-3"
            >
              <div className="h-6 w-6 text-primary hover:opacity-70 transition-all ease-out duration-300">
                {link.icon}
              </div>
            </motion.a>
          ))}
        </div>
      </div>
      <Link
        to="about"
        smooth={true}
        duration={1000}
        // offset={-90}
        className="absolute bottom-16"
      >
        <div className={styles.scroll__icon}></div>
      </Link>
    </div>
  );
}

export default Hero;
