// Technology Logos
import { ReactComponent as ReactLogo } from "assets/icons/logos/react.svg";
import { ReactComponent as NextjsLogo } from "assets/icons/logos/nextjs.svg";
import { ReactComponent as VuejsLogo } from "assets/icons/logos/vuejs.svg";
import { ReactComponent as NuxtjsLogo } from "assets/icons/logos/nuxtjs.svg";
import { ReactComponent as GitLogo } from "assets/icons/logos/git.svg";
import { ReactComponent as HtmlLogo } from "assets/icons/logos/html.svg";
import { ReactComponent as CssLogo } from "assets/icons/logos/css.svg";

// Language Logos
import { ReactComponent as JavaScriptLogo } from "assets/icons/logos/javascript.svg";
import { ReactComponent as TypeScriptLogo } from "assets/icons/logos/typescript.svg";
import { ReactComponent as JavaLogo } from "assets/icons/logos/java.svg";
import { ReactComponent as CppLogo } from "assets/icons/logos/cpp.svg";
import { ReactComponent as CLogo } from "assets/icons/logos/c.svg";
import { ReactComponent as RLogo } from "assets/icons/logos/r.svg";
import { ReactComponent as PHPLogo } from "assets/icons/logos/php.svg";
import { ReactComponent as MySqlLogo } from "assets/icons/logos/mysql.svg";

// logos are sourced from worldvectorlogo.com

export const technologyItems = [
  {
    id: 1,
    title: "React",
    logo: <ReactLogo />,
    color: "#61dafb",
  },
  {
    id: 2,
    title: "Next.js",
    logo: <NextjsLogo />,
    color: "#000000",
  },
  {
    id: 3,
    title: "Vue.js",
    logo: <VuejsLogo />,
    color: "#41B883",
  },
  {
    id: 4,
    title: "Nuxt.js",
    logo: <NuxtjsLogo />,
    color: "#008776",
  },
  {
    id: 5,
    title: "Git",
    logo: <GitLogo />,
    color: "#DE4C36",
  },
  {
    id: 6,
    title: "HTML",
    logo: <HtmlLogo />,
    color: "#e34f26",
  },
  {
    id: 7,
    title: "CSS",
    logo: <CssLogo />,
    color: "#1b73ba",
  },
];

export const languageItems = [
  {
    id: 1,
    title: "JavaScript",
    logo: <JavaScriptLogo />,
    color: "#e3cf46",
  },
  {
    id: 2,
    title: "TypeScript",
    logo: <TypeScriptLogo />,
    color: "#2d79c7",
  },
  {
    id: 2,
    title: "Java",
    logo: <JavaLogo />,
    color: "#E76F00",
  },
  {
    id: 3,
    title: "C++",
    logo: <CppLogo />,
    color: "#00599c",
  },
  {
    id: 4,
    title: "C",
    logo: <CLogo />,
    color: "#1A4674",
  },
  {
    id: 5,
    title: "R",
    logo: <RLogo />,
    color: "#2165B6",
  },
  {
    id: 6,
    title: "PHP",
    logo: <PHPLogo />,
    color: "#6181B6",
  },
  {
    id: 7,
    title: "MySql",
    logo: <MySqlLogo />,
    color: "#00546B",
  },
];
