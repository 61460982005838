import React from "react";
import { projectItems } from "./data";
import ProjectCard from "./ProjectCard";

function Projects() {
  return (
    <div className="container_wrapper container_wrapper__dark" name="projects">
      <h1 className="heading">
        Some of my personal <span className="highlight">projects.</span>
      </h1>
      <div className="max-w-3xl flex flex-col space-y-12 sm:space-y-24">
        {projectItems.map((props) => (
          <ProjectCard props={props} key={props.id} />
        ))}
      </div>
    </div>
  );
}

export default Projects;
