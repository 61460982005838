// import AirbnbClone from "assets/images/projects/airbnb-clone.png";
import CryptoApp from "assets/images/projects/crypto-app.png";
import MusicApp from "assets/images/projects/music-app.png";
import MovieApp from "assets/images/projects/movie-app.png";
import EcommerceWebsite from "assets/images/projects/ecommerce-website.png";

export const projectItems = [
  {
    id: 1,
    img: MovieApp,
    title: "Movie App",
    subtitle: "HTML, CSS, Javascript, React, Context API, Tailwind CSS",
    description:
      "A movie search application that accesses data from the TMDB API. It features popular, top rated and recommended movies, a search function, filtering and sorting. Users can also add movies to a favourites section",
    github: "https://github.com/vtrinh1/Movie-App",
    website: "https://vtrinh-movies.netlify.app/",
  },
  {
    id: 2,
    img: MusicApp,
    title: "Music App",
    subtitle: "HTML, CSS, Javascript, React, Redux, Tailwind CSS",
    description:
      "A music player single page application that features dynamic top artists and charts, music and artist search function, genre filtration and popular songs based on the user's location. Data is fetched from the Shazam Core API.",
    github: "https://github.com/vtrinh1/Music-App",
    website: "https://vince-beats.netlify.app/",
  },
  {
    id: 3,
    img: CryptoApp,
    title: "Cryptocurrency App",
    subtitle: "HTML, CSS, Javascript, React, Redux, Ant Design",
    description:
      "An app that collects details on cryptocurrencies. It was designed using Ant Design and also fetches it's data from Coinranking API. It also includes state management using Redux Toolkit and charts using Chart.js.",
    github: "https://github.com/vtrinh1/Crypto-App",
    website: "https://vtrinh1-cryptohub.netlify.app/",
  },
  // {
  //   id: 4,
  //   img: AirbnbClone,
  //   title: "Airbnb Clone",
  //   subtitle: "HTML, CSS, Javascript, Next.js, Tailwind CSS",
  //   description:
  //     "An Airbnb website clone built with Next.js and Tailwind CSS. It features a home and search results page with server side rendering implemented into it. All its data is extracted from static APIs and uses a custom map from Mapbox.",
  //   github: "https://github.com/vtrinh1/Airbnb-Clone",
  //   website: "https://vtrinh1-airbnb-clone.netlify.app/",
  // },
  {
    id: 4,
    img: EcommerceWebsite,
    title: "E-Commerce Website",
    subtitle: "HTML, CSS, Javascript, React, Styled-Components",
    description:
      "An eCommerce website designed and built from scratch. It makes use of functional reusable react components, react hooks and styled-components. The website has inbuilt category, product, login, register and shopping cart pages.",
    github: "https://github.com/vtrinh1/Online-Store-Website",
    website: "https://vtrinh1-trinhs.netlify.app/",
  },
];
