import React, { useRef, useState } from "react";
import isEmail from "validator/lib/isEmail";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./Contact.module.css";
import emailjs from "@emailjs/browser";
import { ReactComponent as LoadingIcon } from "assets/icons/loading.svg";

function Contact({ isOn }) {
  const refCaptcha = useRef();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonName, setButtonName] = useState("Send");
  const [buttonStyle, setButtonStyle] = useState(
    "bg-primary border-primary hover:text-primary dark:hover:text-primary"
  );
  const [formError, setFormError] = useState({
    name: "",
    email: "",
    message: "",
    ReCaptcha: "",
  });

  const [emailForm, setEmailForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  function handleChange(event) {
    const inputId = event.target.id;
    setEmailForm({
      ...emailForm,
      [inputId]: event.target.value,
    });
    if (event.target.value === "") {
      const inputIdCapitalized =
        inputId.charAt(0).toUpperCase() + inputId.slice(1);
      setFormError({
        ...formError,
        [inputId]: `${inputIdCapitalized} is required.`,
      });
    } else {
      setFormError({ ...formError, [inputId]: "" });
    }
  }

  function handleCaptchaChange() {
    setFormError({
      ...formError,
      ReCaptcha: "",
    });
  }

  function handleSubmit(event) {
    event.preventDefault();

    const token = refCaptcha.current.getValue();

    if (
      emailForm.name === "" ||
      emailForm.email === "" ||
      isEmail(emailForm.email) === false ||
      emailForm.message === "" ||
      token === ""
    ) {
      // Object.keys(emailForm).forEach((key) => {
      //   var FormErrorTitle = {};
      //   FormErrorTitle["title"] = key;
      //   if (emailForm[key] === "") {
      //     setFormError({
      //       ...formError,
      //       [FormErrorTitle.title]: `${key} is required.`,
      //     });
      //   }
      // });
      setFormError({
        name: emailForm.name === "" && "Name is required",
        email: emailForm.email === "" && "Email is required",
        message: emailForm.message === "" && "Message is required",
        ReCaptcha: token === "" && "Verification is required",
      });
      if (emailForm.email !== "") {
        if (isEmail(emailForm.email) === false) {
          console.log("email is false");
          setFormError({
            ...formError,
            email: "Email is invalid.",
          });
        }
      }
      return;
    }

    if (isEmail(emailForm.email) === false) {
      return;
    }

    setButtonDisabled(true);
    setButtonName(
      <LoadingIcon
        id="submit_sending"
        className="flex animate-spin h-5 w-5 mr-2 -ml-1 text-white dark:text-gray-200 group-hover:text-primary transition-all duration-300 ease-in-out"
      />
    );

    const params = {
      ...emailForm,
      "g-recaptcha-response": token,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        params,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setButtonName("Sent!");
          setButtonStyle(
            "bg-slate-600 border-slate-600 hover:text-slate-600 dark:hover:text-slate-600"
          );
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  }

  return (
    <div className="container_wrapper container_wrapper__light" name="contact">
      <div className="flex items-center flex-col mb-12 sm:mb-24">
        <h1 className="text-3xl sm:text-5xl mb-4 sm:mb-6 text-center">
          Send me a <span className="highlight">message!</span>
        </h1>
        <h2 className="text-lg sm:text-2xl font-normal text-center w-full max-w-xs sm:max-w-md">
          Got a question or proposal, or just want to say hello? Go ahead.
        </h2>
      </div>
      <form
        onSubmit={handleSubmit}
        className="w-full max-w-4xl flex flex-col items-center"
      >
        <div className="w-full flex flex-col lg:flex-row justify-center lg:space-x-20 lg:mb-8">
          <label className={styles.contact__input_wrapper}>
            <span className={styles.contact__input_label}>Your Name</span>
            <input
              id="name"
              type="text"
              name="Name"
              placeholder="Enter your name"
              className={styles.contact__input}
              onChange={handleChange}
            />
            <p className={styles.contact__input_error}>{formError.name}</p>
          </label>
          <label className={`${styles.contact__input_wrapper} relative`}>
            <span className={styles.contact__input_label}>Email Address</span>
            <input
              id="email"
              type="text"
              name="Email"
              placeholder="Enter your email address"
              className={`${styles.contact__input} peer`}
              onChange={handleChange}
            />
            <p className={styles.contact__input_error}>{formError.email}</p>
          </label>
        </div>
        <label className={`${styles.contact__input_wrapper} mb-24`}>
          <span className={styles.contact__input_label}>Your Message</span>
          <textarea
            id="message"
            name="Message"
            placeholder="Hi, I think we need to develop an app for our products at Company X. How soon can you hop on to discuss this?"
            className={`${styles.contact__input} ${styles.contact__text_area}`}
            onChange={handleChange}
          />
          <p className={styles.contact__input_error}>{formError.message}</p>
        </label>
        <label className="relative flex flex-col mb-12 sm:mb-24 items-center">
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            ref={refCaptcha}
            onChange={handleCaptchaChange}
            theme={isOn ? "dark" : "light"}
            className="mb-1"
          />
          <p className={styles.contact__input_error}>{formError.ReCaptcha}</p>
        </label>
        <button
          disabled={buttonDisabled}
          type="submit"
          value="Send"
          className={`flex justify-center items-center cursor-pointer rounded text-white dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-neutral-custom text-center h-12 sm:h-16 w-48 sm:w-64 text-md sm:text-xl font-semibold border-2 transition duration-300 ease-in-out uppercase group peer disabled:cursor-not-allowed disabled:opacity-70 ${buttonStyle}`}
        >
          <p>{buttonName}</p>
        </button>
      </form>
    </div>
  );
}

export default Contact;
