import About from "components/About/About";
import Contact from "components/Contact/Contact";
import Footer from "components/Footer/Footer";
import Hero from "components/Hero/Hero";
import NavBar from "components/NavBar/NavBar";
import Projects from "components/Projects/Projects";
import Skills from "components/Skills/Skills";
import { useState } from "react";
import { motion } from "framer-motion";
import { staggerContainer } from "utils/motion";

function App() {
  const [isOn, setIsOn] = useState(() => {
    if (localStorage.getItem("theme") === "light") {
      return true;
    } else {
      return false;
    }
  });
  return (
    <div>
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false }}
      >
        <NavBar isOn={isOn} setIsOn={setIsOn} />
        <Hero />
      </motion.div>
      <About />
      <Skills />
      <Projects />
      <Contact isOn={isOn} />
      <Footer />
    </div>
  );
}

export default App;
