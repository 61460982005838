import React from "react";
import { Link } from "react-scroll";
import { motion } from "framer-motion";
import styles from "./NavBar.module.css";
import { navVariants } from "utils/motion";
// import DarkModeSwitch from "./DarkModeSwitch";
import Logo from "assets/images/logo.png";

function NavBar({ isOn, setIsOn }) {
  return (
    <motion.div
      variants={navVariants("down", 0.5)}
      initial="hidden"
      whileInView="show"
      className="absolute w-full flex justify-center py-6 px-6 z-20"
    >
      <div className={`absolute w-[50%] inset-0 ${styles.gradient}`} />
      <div className="max-w-6xl w-full flex justify-between items-center">
        <img src={Logo} alt="Logo" className="w-14 py-1 ml-2" />
        {/* <ul className="hidden md:flex space-x-4"> */}
        <ul className="flex space-x-4">
          {["about", "skills", "projects", "contact"].map((item) => (
            <li key={item}>
              <Link
                to={item}
                spy={true}
                smooth={true}
                duration={1000}
                className={`${
                  item !== "contact" && "hidden md:inline"
                } capitalize text-center hover:text-primary transition-all duration-300 ease-in-out`}
              >
                <div className="w-20 cursor-pointer py-2">{item}</div>
              </Link>
            </li>
          ))}
          {/* <li>
              <DarkModeSwitch isOn={isOn} setIsOn={setIsOn} />
            </li> */}
        </ul>
      </div>
    </motion.div>
  );
}

export default NavBar;
