import { ReactComponent as GitHubIcon } from "assets/icons/socials/github.svg";
import { ReactComponent as LinkedInIcon } from "assets/icons/socials/linkedin.svg";
import { ReactComponent as MailIcon } from "assets/icons/socials/mail.svg";
import { ReactComponent as ResumeIcon } from "assets/icons/socials/resume.svg";
import resume from "assets/resume.pdf";

export const socialItems = [
  {
    id: "GitHub",
    icon: <GitHubIcon />,
    name: "GitHub",
    contact: false,
    network: true,
    href: "https://github.com/vtrinh1",
  },
  {
    id: "LinkedIn",
    icon: <LinkedInIcon />,
    name: "LinkedIn",
    contact: true,
    network: true,
    href: "https://www.linkedin.com/in/vincenttrinh1/",
  },
  {
    id: "Email",
    icon: <MailIcon />,
    name: "vincent.trinh1@hotmail.com",
    contact: true,
    network: true,
    href: "mailto:vincent.trinh1@hotmail.com",
  },
  {
    id: "Resume",
    icon: <ResumeIcon />,
    name: "Resume",
    contact: true,
    network: false,
    href: resume,
  },
];
