import React from "react";
import { motion } from "framer-motion";
import { fadeIn, staggerContainer, textVariant } from "utils/motion";
import Selfie from "assets/images/selfie.png";
import Resume from "assets/resume.pdf";
import Experience from "components/Experience/Experience";

function About() {
  return (
    <div className="container_wrapper container_wrapper__dark" name="about">
      <motion.div
        variants={staggerContainer}
        initial="hidden"
        whileInView="show"
        viewport={{ once: false }}
      >
        <motion.h1 variants={textVariant(0.5)} className="heading">
          Learn more about <span className="text-primary">me.</span>
        </motion.h1>
        <div className="flex flex-col lg:flex-row items-center justify-center mb-12 sm:mb-16 lg:mb-24 max-w-3xl lg:max-w-5xl">
          <motion.div
            variants={textVariant(0.75)}
            className="flex items-center justify-center mb-12 lg:mb-0 lg:w-1/2"
          >
            <img
              src={Selfie}
              alt="Selfie"
              className="w-48 sm:w-72 lg:w-96 rounded-full"
            />
          </motion.div>
          <div className="lg:w-1/2 flex flex-col items-center lg:items-start space-y-4 lg:pr-12 text-center lg:text-left">
            <motion.p
              variants={fadeIn("up", "spring", 0.75 + 0.25, 1.5)}
              className="desc"
            >
              Building <span className="highlight">beautiful</span>,{" "}
              <span className="highlight">customisable</span> and{" "}
              <span className="highlight">responsive</span> user experiences is
              my passion. I enjoy pushing myself to solve problems and{" "}
              <span className="highlight">creating solutions</span> that
              positively affect people.
            </motion.p>
            <motion.p
              variants={fadeIn("up", "spring", 0.75 + 0.5, 1.5)}
              className="desc"
            >
              In building web applications, I'm equipped with just the{" "}
              <span className="highlight">right tools</span>, and can absolutely
              function independently to deliver fast, resilient solutions
              optimized for scale —{" "}
              <span className="highlight">performance</span> and{" "}
              <span className="highlight">scalabilty</span> are priorities on my
              radar.
            </motion.p>
            <div className="space-y-4 duration-100 w-full">
              <motion.p
                variants={fadeIn("up", "spring", 0.75 + 0.75, 1.5)}
                className="desc"
              >
                Check out my resume!
              </motion.p>
              <motion.div variants={fadeIn("up", "spring", 0.75 + 1, 1.5)}>
                <a
                  href={Resume}
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-center cursor-pointer rounded py-2 sm:py-3 bg-primary text-white text-center w-48 sm:w-64 text-md sm:text-xl font-semibold border-2 border-primary hover:bg-gray-custom dark:hover:bg-neutral-900 hover:text-primary transition duration-300 ease-in-out uppercase mx-auto lg:mx-0"
                >
                  Resume
                </a>
              </motion.div>
            </div>
          </div>
        </div>
      </motion.div>
      <Experience />
    </div>
  );
}

export default About;
