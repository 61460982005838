import { ReactComponent as LaptopIcon } from "assets/icons/laptop.svg";
import { ReactComponent as EducationIcon } from "assets/icons/education.svg";

// Stack Logos
import { ReactComponent as ReactLogo } from "assets/icons/logos/react.svg";
import { ReactComponent as NextjsLogo } from "assets/icons/logos/nextjs.svg";
import { ReactComponent as VuejsLogo } from "assets/icons/logos/vuejs.svg";
import { ReactComponent as NuxtjsLogo } from "assets/icons/logos/nuxtjs.svg";
import { ReactComponent as HtmlLogo } from "assets/icons/logos/html.svg";
import { ReactComponent as CssLogo } from "assets/icons/logos/css.svg";
import { ReactComponent as JavaScriptLogo } from "assets/icons/logos/javascript.svg";
import { ReactComponent as MySqlLogo } from "assets/icons/logos/mysql.svg";

export const experienceItems = [
  {
    id: 1,
    role: "Junior Frontend Developer",
    company: "BTC.com.au",
    description:
      "Developed the brochureware site with Nuxt.js and then rebuilt it using Next.js. Architected frontened features on the crypto exchange web app.",
    stack: ["JavaScript", "React", "Next.js", "Vue.js", "Nuxt.js"],
    date: "May 2022 - Jun 2023",
    icon: <LaptopIcon />,
  },
  {
    id: 2,
    role: "Programming Tutor",
    company: "Self-employed",
    description:
      "Tutored multiple students and assisted them in developing industry-standard methods of responsive web design & overall exceptional coding practices.",
    stack: ["HTML", "CSS", "JavaScript", "MySql"],
    date: "Nov 2022 - May 2022",
    icon: <LaptopIcon />,
  },
  {
    id: 3,
    role: "Information Technology Operator",
    company: "TES-AMM",
    description:
      "Created and maintained hardware or software inventory database while performing preventive maintenance on all computer systems.",
    date: "Jun 2021 - Dec 2021",
    icon: <LaptopIcon />,
  },
  {
    id: 4,
    role: "Bachelor of Computer Science",
    company: "Western Sydney University",
    description: "Notable completed units:",
    units: [
      "Data Structures and Algorithms",
      "Development Database Design and Development",
      "Technologies for Web Applications",
    ],
    date: "Expected Nov 2023",
    icon: <EducationIcon />,
  },
];

export const stackItems = [
  { id: "JavaScript", color: "#e3cf46", logo: <JavaScriptLogo /> },
  { id: "React", color: "#61dafb", logo: <ReactLogo /> },
  { id: "Next.js", color: "#000000", logo: <NextjsLogo /> },
  { id: "Vue.js", color: "#41B883", logo: <VuejsLogo /> },
  { id: "Nuxt.js", color: "#008776", logo: <NuxtjsLogo /> },
  { id: "HTML", color: "#e34f26", logo: <HtmlLogo /> },
  { id: "CSS", color: "#1b73ba", logo: <CssLogo /> },
  { id: "MySql", color: "#00546B", logo: <MySqlLogo /> },
];
