import React from "react";

function SkillItem(props) {
  const item = props.props;
  return (
    <div className="relative group w-24 xs:w-28 sm:w-32 h-24 xs:h-28 sm:h-32 rounded-2xl shadow-lg flex items-center justify-center dark:bg-neutral-800 bg-gray-50 duration-300">
      <div className="w-8 xs:w-10 sm:w-12 h-8 xs:h-10 sm:h-12 group-hover:scale-90 group-hover:brightness-90 group-hover:opacity-80 transition duration-300 ease-in-out flex items-center justify-center">
        {item.logo}
      </div>
      <div
        className="absolute bottom-2 scale-0 opacity-0 group-hover:scale-100 group-hover:opacity-100 transition duration-300 ease-in-out font-semibold text-sm sm:text-base"
        style={{
          color: item.color && item.color
        }}
      >
        {item.title}
      </div>
    </div>
  );
}

export default SkillItem;
