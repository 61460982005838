import React from "react";
import { experienceItems, stackItems } from "./data";
import { ReactComponent as CaretIcon } from "assets/icons/caret.svg";

function Experience() {
  return (
    <div className="max-w-6xl w-full">
      {experienceItems.map((props) => (
        <div key={props.id} className="w-full py-4 lg:py-10 relative">
          <div className="flex">
            <div
              className={`lg:w-1/2 w-full pl-16 ${
                props.id % 2 === 0
                  ? "lg:pl-16 lg:order-last"
                  : "lg:pl-0 lg:pr-16"
              }`}
            >
              <div className="relative bg-white dark:bg-neutral-800 rounded-md p-4 lg:p-6 mt-1 duration-300">
                <h2 className="text-lg sm:text-xl tracking-wide font-semibold text-slate-700 dark:text-slate-200 duration-300">
                  {props.role}
                </h2>
                <h3 className="text-base sm:text-lg text-slate-400 dark:text-slate-400 font-semibold tracking-wide duration-300">
                  {props.company}
                </h3>
                <p className="text-base sm:text-lg text-slate-600 dark:text-slate-300 tracking-normal mt-4 duration-300">
                  {props.description}
                </p>
                {props.stack && (
                  <div className="flex flex-wrap gap-x-2 sm:gap-x-4 gap-y-2 mt-2 sm:mt-4 text-slate-700 dark:text-slate-300 duration-300">
                    {props.stack.map((item) =>
                      stackItems
                        .filter((stackItem) => stackItem.id === item)
                        .map((stackItem, index) => (
                          <div
                            key={index}
                            className="flex items-center text-sm py-1 sm:py-2 px-2 sm:px-3 bg-slate-100 dark:bg-neutral-700 rounded-full border-b-2 duration-300"
                            style={{
                              borderColor: stackItem.color,
                            }}
                          >
                            <div className="w-4 sm:w-6 h-4 sm:h-6 flex items-center justify-center mr-1 sm:mr-2">
                              {stackItem.logo}
                            </div>
                            {stackItem.id}
                          </div>
                        ))
                    )}
                  </div>
                )}
                {props.units &&
                  props.units.map((unit, index) => (
                    <div
                      className="flex items-center mt-0.5 sm:mt-1 text-slate-600 dark:text-slate-300 duration-300"
                      key={index}
                    >
                      <CaretIcon className="w-3 min-w-[0.75rem] sm:w-4 sm:min-w-[1rem] mr-1 sm:mr-2 text-slate-600 dark:text-slate-300 duration-300" />
                      <div className="text-base sm:text-lg font-normal tracking-normal">
                        {unit}
                      </div>
                    </div>
                  ))}
                <p className="lg:hidden text-sm text-slate-500 dark:text-slate-400 mt-6 duration-300">
                  {props.date}
                </p>
                <div
                  className={`absolute rotate-45 bg-white dark:bg-neutral-800 top-3 lg:top-5 h-4 w-4 -left-1 duration-300 ${
                    props.id % 2 !== 0 && " lg:-right-1 lg:left-auto"
                  }`}
                />
              </div>
            </div>
            <div
              className={`hidden h-16 w-1/2 text-slate-500 dark:text-slate-400 lg:flex items-center duration-300 ${
                props.id % 2 === 0 ? "pr-14 justify-end" : "pl-14"
              }`}
            >
              <p>{props.date}</p>
            </div>
          </div>
          <div className="absolute lg:ml-auto lg:mr-auto lg:left-0 lg:right-0 lg:text-center top-4 lg:top-10 z-10 flex items-center justify-center w-12 h-12 lg:w-16 lg:h-16 bg-primary border-4 border-white dark:border-neutral-800 rounded-full duration-300">
            <div className="w-6 h-6 text-white dark:text-slate-200 duration-300">
              {props.icon}
            </div>
          </div>
          <div className="absolute w-1 h-full bg-white dark:bg-neutral-800 lg:ml-auto lg:mr-auto lg:left-0 lg:right-0 lg:text-center left-[1.375rem] top-0 duration-300" />
        </div>
      ))}
    </div>
  );
}

export default Experience;
