import React from "react";
import styles from "./Skills.module.css";
import { technologyItems, languageItems } from "./data";
import SkillCard from "./SkillCard";

function Skills() {
  return (
    <div className="container_wrapper container_wrapper__light" name="skills">
      <h1 className="heading">
        My <span className="highlight">skill stack.</span>
      </h1>
      <div className="flex justify-between items-center space-y-16 lg:space-y-0 max-w-lg lg:space-x-16 sm:max-w-7xl w-full flex-col lg:flex-row">
        <div className={styles.skills__wrapper}>
          <h2 className={styles.skills__title}>
            <span className="highlight">Technologies</span> I use.
          </h2>
          <div className={styles.skill_cards__wrapper}>
            {technologyItems.map((props) => (
              <SkillCard props={props} key={props.id} />
            ))}
          </div>
        </div>
        <div className={styles.skills__wrapper}>
          <h2 className={styles.skills__title}>
            <span className="highlight">Languages</span> I work with.
          </h2>
          <div className={styles.skill_cards__wrapper}>
            {languageItems.map((props) => (
              <SkillCard props={props} key={props.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Skills;
